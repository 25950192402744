<template>
  <main>
    <mandiri />
  </main>
</template>
<script>
import MandiriRasionalisasi from "@/views/pages/admin/rasionalisasi/Mandiri.vue";
export default {
  components: {
    Mandiri: MandiriRasionalisasi,
  },
};
</script>
